
import { Component, Vue } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'

@Component({
  components: {
    SellerHeader,
  },
})
export default class AdministrationMenu extends Vue {
  jwt = localStorage.getItem('access_token')

  goToCreateEvent() {
    const jwt = localStorage.getItem('access_token')
    window.location.href =
      process.env.VUE_APP_ADMIN_DOMAIN + '/events/create' + '?jwt=' + jwt
  }

  v2Birthday(route: string): void {
    const jwt = localStorage.getItem('access_token')
    window.location.href =
     process.env.VUE_APP_REPORTS_APP_URL + route + '?jwt=' + jwt
  }
}
